.App {
  margin: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main-container {
  margin: 32px;
}
canvas {
  width: 600px;

  border: 1px solid black;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Nanum Gothic', sans-serif;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  width: 400px;
  margin-bottom: 36px;
}

input[type='text'] {
  margin-bottom: 16px;
  padding: 10px;
  border: none;

  border-radius: 5px;
  background-color: #f6f6f6;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  color: #333;
  transition: all 0.2s ease-in-out;
}

input[type='text']:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
label {
  font-size: 14px;
  color: #333;
}

button[type='submit'] {
  padding: 10px 20px;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color linear 0.3s;
}

button[type='submit']:hover {
  background-color: #0069d9;
}

button[disabled] {
  background-color: #ccc;
  color: #999;
  cursor: not-allowed;
}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.color-option {
  width: 50px;
  height: 50px;
}
.canvas-images {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* gap: 10px; */
  width: 600px;
}
.canvas-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
}
.api-key-explanation {
  font-size: 14px;
  color: #f64d05;
  margin: 0;
  line-height: 1.5;
  font-weight: 500;
}
.reset-button {
  padding: 10px 20px;
  background-color: #848d96;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color linear 0.3s;
}
textarea {
  overflow: hidden;
  font-family: 'Nanum Gothic', sans-serif;
  font-size: 13px;
}
